import { useQuery } from '@apollo/client';

import { BusinessSearchQuery, BusinessSearchQueryVariables } from 'shared/graphql/__generated__';

import { BUSINESS_SEARCH_QUERY } from '../queries';

export const useBusinessSearch = (id: string) => {
  const { loading, data, ...rest } = useQuery<BusinessSearchQuery, BusinessSearchQueryVariables>(
    BUSINESS_SEARCH_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: { filter: { id: { equals: id } } },
    },
  );

  return {
    business: data?.organizationsList?.items[0],
    loading: loading && !data,
    ...rest,
  };
};
